<template>
  <section>
    <!-- title -->
    <h5 class="text-h5 secondary--text mb-8">Registro de informes de compras por emergencias</h5>

    <!-- body -->
    <v-row class="mb-4">
      <v-col cols="12" md="5">
        <v-autocomplete
          label="Fondos asignados *"
          :items="ctl_fondos_var"
          item-text="nombre"
          item-value="id"
          v-model="id_fondo_selected_var"
          outlined
          @change="FetchListaInformesById"
          clearable
          hide-details
        />
      </v-col>
      <v-col cols="4" md="2">
        <v-btn
          block
          :disabled="!id_fondo_selected_var"
          class="text-no-style"
          color="secondary"
          @click="show_modal_add_informe = true"
          v-show="!this.diferentUser"
        >
          Añadir informe
        </v-btn>
      </v-col>
    </v-row>

    <v-divider class="mb-4" />

    <v-row class="justify-center" v-if="fondo_var">
      <v-col cols="12" sm="4" lg="2" class="text-center">
        <p class="m-0">Total asignado:</p>
        <h6 class="text-h6">
          $
          {{
            Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(fondo_var?.monto_establecido)
          }}
        </h6>
      </v-col>
      <v-col cols="12" sm="4" lg="2" class="text-center">
        <p class="m-0">Total disponible:</p>
        <h6 class="text-h6">
          $
          {{
            Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(fondo_var?.monto_disponible)
          }}
        </h6>
      </v-col>
    </v-row>

    <!-- modal agregar informe -->
    <v-dialog v-model="show_modal_add_informe" persistent max-width="950">
      <v-card>
        <div class="headerIcon d-flex justify-end">
          <v-icon class="secondary--text" @click="CloseModalAddInforme">
            mdi-close
          </v-icon>
        </div>
        <v-card-text>
          <formInforme
            @close="CloseModalAddInforme"
            @actualizarInforme="cerrarActualizarTablaInforme"
            :id_fondo="id_fondo_selected_var"
            v-if="show_modal_add_informe"
          ></formInforme>
          <!-- acciones -->
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Tabla para visualizar la tabla de informes -->
    <template v-if="id_fondo_selected_var">
      <data-table-component
        :headers="headers_informes"
        :items="list_informes_var"
        :show_actions="true"
        :total_registros="total_rows_var"
        @paginar="PaginateFtn"
        v-models:pagina="pagination_var.page"
        v-models:select="pagination_var.per_page"
        class="admin-fondos-compra-por-emergencia-table mt-4"
      >
        <!-- Slot de mes -->
        <template v-slot:item.nombre_informe="{ item }">
          {{ item?.nombre }}
        </template>

        <template v-slot:item.fondo="{ item }">
          {{ item?.FondoCompraEmergencium?.nombre }}
        </template>

        <template v-slot:[`item.acciones`]="{ item }">
          <div class="d-flex align-center justify-center px-2" style="gap: 8px">
            <DataTableButton
              icon="mdi-receipt-text-plus-outline"
              icon-color="secondary"
              tooltipText="Agregar registros al informe"
              tooltipColor="blueGrayMinsal"
              customClass="mr-0"
              @click="openModalAgregarRegistrosInforme(item)"
            />

            <template>
              <v-tooltip top color="blueGrayMinsal">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click.stop="openAdjuntoInforme(item)"
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    icon
                  >
                    <v-icon>mdi-file-eye-outline</v-icon>
                  </v-btn>
                </template>
                <span>Ver adjunto</span>
              </v-tooltip>
            </template>
            
          </div>
        </template>
      </data-table-component>
    </template>

    <!-- modal agregar registros dentro del informe y tabla de registros -->
    <v-dialog v-model="show_modal_add_registros_informe" persistent max-width="2000">
      <v-card>
        <div class="headerIcon d-flex justify-end">
          <v-icon class="secondary--text" @click="closeModalAddRegistrosInforme">
            mdi-close
          </v-icon>
        </div>
        <v-card-text>
          <template>
            <v-row>
              <v-col cols="4" md="2">
                <v-btn 
                  class="text-no-style"
                  block
                  outlined
                  @click="closeModalAddRegistrosInforme">
                  Cancelar
                </v-btn>
              </v-col>
              <v-col cols="4" md="2">
                <v-btn
                    block
                    :disabled="!id_fondo_selected_var"
                    class="text-no-style"
                    color="secondary"
                    @click="show_modal_add_var = true"
                    v-show="!this.diferentUser"
                  >
                  Añadir registros
                </v-btn>
              </v-col>
              <v-col cols="4" md="2">
                <v-btn
                  block
                  outlined
                  :disabled="!id_fondo_selected_var"
                  class="text-no-style"
                  color="secondary"
                  @click="show_upload_excel_file = true"
                  v-show="!this.diferentUser"
                >
                  <v-icon color="secondary">mdi-file-excel</v-icon> Cargar desde excel
                </v-btn>
              </v-col>
            </v-row>
            <data-table-component
              :headers="HEADERS_CONST"
              :items="list_regs_var"
              :show_actions="true"
              :total_registros="total_rows_var"
              @paginar="paginateFtnRegistrosInforme"
              v-models:pagina="pagination_var.page"
              v-models:select="pagination_var.per_page"
              class="admin-fondos-compra-por-emergencia-table mt-4"
            >

              <template v-slot:item.descripcion_uso="{ item }">
              {{ item?.descripcion_uso }}
              </template>

              <template v-slot:item.tipo_documento_contractual="{ item }">
                <v-chip>
                  {{ mapTipoDocumentoContractual(item.tipo_documento_contractual) }}
                </v-chip>
              </template>
              
              <template v-slot:item.numero_documento_contractual="{ item }">
              {{ item?.numero_documento_contractual }}
              </template>

              <template v-slot:item.nombre_proveedor="{ item }">
              {{ item?.nombre_proveedor }}
              </template>

              <template v-slot:item.nombre_producto_servicio="{ item }">
              {{ item?.nombre_producto_servicio }}
              </template>

              <template v-slot:item.cantidad="{ item }">
              {{ item?.cantidad }}
              </template>

              <template v-slot:item.precio_unitario="{ item }">
              {{ item?.precio_unitario }}
              </template>

              <template v-slot:item.total_compra="{ item }">
              {{ item?.total_compra }}
              </template>

              <template v-slot:[`item.acciones`]="{ item }">
                <div class="d-flex align-center justify-center px-2" style="gap: 8px">
                  <DataTableButton
                    icon="mdi-eye"
                    icon-color="secondary"
                    tooltipText="Ver detalles"
                    tooltipColor="blueGrayMinsal"
                    customClass="mr-0"
                    @click="OpenModalDetailFtn(item)"
                  />
                </div>
              </template>
            </data-table-component>
            <br />
            <br />
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>


    <!-- modal agregar registros de compras -->
    <v-dialog v-model="show_modal_add_var" persistent max-width="950">
      <v-card>
        <div class="headerIcon d-flex justify-end">
          <v-icon class="secondary--text" @click="CloseModalAddFtn">
            mdi-close
          </v-icon>
        </div>
        <v-card-text>
          <formCompra
            @close="CloseModalAddFtn"
            @actualizar="cerrarYActualizar"
            :id_fondo="id_fondo_selected_var"
            :id_registro_informe_fondo="id_reg_informe"
            v-if="show_modal_add_var"
          ></formCompra>
          <!-- acciones -->
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- modal detalle -->
    <v-dialog v-model="show_modal_detail_var" persistent max-width="800">
      <v-card>
        <div class="headerIcon">
          <v-icon class="secondary--text" @click="CloseModalDetailFtn">
            mdi-close
          </v-icon>
        </div>
        <v-card-text>
          <!-- registrar fondo -->
          <v-row class="mx-sm-6">
            <v-col cols="12">
              <span class="text-h6 secondary--text">Detalle de fondo</span>
            </v-col>
            <v-col cols="12" class="d-flex flex-column">
              <span v-text="'Fondo asignado'" />
              <span
                class="text-h6 secondary--text mb-3"
                v-text="fondo_var?.nombre"
              />

              <span v-text="'Fecha D.L. O D.E.'" />
              <span class="text-h6 secondary--text mb-3">
                {{ moment(detail_regs_var?.fecha_dl_de).format("DD/MM/YYYY") }}
              </span>

              <span v-text="'N° D.L. ó D.E.'" />
              <span
                class="text-h6 secondary--text mb-3"
                v-text="detail_regs_var?.numero_dl_de"
              />

              <span v-text="'Fecha de resolución'" />
              <span class="text-h6 secondary--text mb-3">
                {{ moment(detail_regs_var?.fecha_resolucion).format("DD/MM/YYYY") }}
              </span>

              <span v-text="'Referencia de la resolución'" />
              <span
                class="text-h6 secondary--text mb-3"
                v-text="detail_regs_var?.referencia_resolucion"
              />

              <span v-text="'Tipo documento fiscal o equivalente'" />
              <span
                class="text-h6 secondary--text mb-3"
                v-text="detail_regs_var?.tipo_documento_fiscal"
              />

              <span v-text="'Fecha de emisión documento fiscal'" />
              <span class="text-h6 secondary--text mb-3">
                {{ moment(detail_regs_var?.fecha_emision_fiscal).format("DD/MM/YYYY") }}
              </span>

              <span v-text="'Número documento fiscal o equivalente'" />
              <span
                class="text-h6 secondary--text mb-3"
                v-text="detail_regs_var?.numero_documento_fiscal"
              />

              <span v-text="'Documento de proveedor'" />
              <span
                class="text-h6 secondary--text mb-3"
                v-text="detail_regs_var?.numero_documento"
              />

              <span v-text="'Nombre del proveedor'" />
              <span
                class="text-h6 secondary--text mb-3"
                v-text="detail_regs_var?.nombre_proveedor"
              />

              <span v-text="'Retenciones'" />
              <span class="text-h6 secondary--text mb-3">
                {{
                  Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(detail_regs_var?.monto_retenido)
                }}
              </span>

              <span v-text="'Observación u comentarios'" />
              <span
                class="text-h6 secondary--text mb-3"
                v-text="detail_regs_var?.observaciones"
              />

            </v-col>
          </v-row>
          <!-- acciones -->
          <v-row
            cols="12"
            class="d-flex flex-column flex-sm-row mx-sm-4 mt-6 mb-3"
          >
            <v-btn
              class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
              color="secondary"
              outlined
              @click="CloseModalDetailFtn"
            >
              Cerrar
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- modal upload excel file -->
    <v-dialog v-model="show_upload_excel_file" persistent max-width="600">
      <v-card>
        <v-card-title>Importar datos desde excel</v-card-title>
        <v-card-text>
          <v-alert type="warning">No está permitido el uso de signos como <b class="black--text">$ "" ' - _ + ~ * ^ {} [] #</b> dentro del formato de excel, así tampoco se deben agregar líneas adicionales, por ejemplo totales, al final de las columnas</v-alert>
          <v-btn @click="DescargarArchivo" text class="text-capitalize pa-0 mb-4" color="green"
            ><v-icon color="green" class="mr-2">mdi-download</v-icon> Descargar
            formato</v-btn
          >
          <p>
            Para poder importar un archivo excel, este debe tener las siguientes
            columnas a partir de la columna A1:
          </p>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td width="40%">Fecha D.l o D.E</td>
                  <td width="60%">Formato día/mes/año, por ejemplo 01/01/2023. IMPORTANTE: El formato de las celdas debe ser en TEXTO</td>
                </tr>
                <tr>
                  <td width="40%">Número D.l o D.E</td>
                  <td width="60%">Número del decreto</td>
                </tr>
                <tr>
                  <td width="40%">Fecha de resolución</td>
                  <td width="60%">Formato día/mes/año, por ejemplo 01/01/2023. IMPORTANTE: El formato de las celdas debe ser en TEXTO</td>
                </tr>
                <tr>
                  <td width="40%">Referencia de la resolución</td>
                  <td width="60%">Número de referencia a colocar de la resolución, puede contener números y letras</td>
                </tr>
                <tr>
                  <td width="40%">Descripción uso de producto y/o servicio</td>
                  <td width="60%">Puede contener números y letras</td>
                </tr>
                <tr>
                  <td width="40%">Tipo documento contractual</td>
                  <td width="60%">Formato texto. Escribir: ORDEN DE COMPRA ó CONTRATO</td>
                </tr>
                <tr>
                  <td width="40%">Número documento contractual</td>
                  <td width="60%">Número que identifica el documento contractual</td>
                </tr>
                <tr>
                  <td width="40%">Tipo documento fiscal o equivalente</td>
                  <td width="60%">Puede contener números o letras</td>
                </tr>
                <tr>
                  <td width="40%">Fecha de emisión de documento fiscal</td>
                  <td width="60%">Formato día/mes/año, por ejemplo 01/01/2023. IMPORTANTE: El formato de las celdas debe ser en TEXTO</td>
                </tr>
                <tr>
                  <td width="40%">Documento del proveedor</td>
                  <td width="60%">Puede contener números o letras</td>
                </tr>
                <tr>
                  <td width="40%">Nombre del proveedor</td>
                  <td width="60%">Puede contener números o letras</td>
                </tr>
                <tr>
                  <td width="40%">Nombre del producto y/o servicio</td>
                  <td width="60%">Puede contener números o letras</td>
                </tr>
                <tr>
                  <td width="40%">Cantidad</td>
                  <td width="60%">Solo valores enteros</td>
                </tr>
                <tr>
                  <td width="40%">Precio unitario</td>
                  <td width="60%">Solo valores numéricos</td>
                </tr>
                <tr>
                  <td width="40%">Total compra</td>
                  <td width="60%">Solo valores numéricos</td>
                </tr>
                <tr>
                  <td width="40%">Retenciones</td>
                  <td width="60%">Si no aplica, entonces ingrese 0 (cero)</td>
                </tr>
                <tr>
                  <td width="40%">Observaciones</td>
                  <td width="60%">Puede contener números o letras</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-file-input
            label="Archivo excel *"
            v-model="file_excel_var"
            accept=".xlsx"
            outlined
            prepend-icon=""
            placeholder="Adjuntar archivo"
            prepend-inner-icon="mdi-paperclip"
            class="mt-4"
            hide-details
          />
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            class="text-capitalize"
            color="secondary"
            min-width="30%"
            outlined
            @click="CloseModalUploadExcelFile"
          >
            Cerrar
          </v-btn>
          <v-btn
            class="text-capitalize"
            color="secondary"
            min-width="30%"
            solid
            @click="uploadExcelFile"
          >
            Cargar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- modal agregar -->
    <v-dialog v-model="show_modal_agregar_adjunto_compra" persistent max-width="950">
      <v-card>
        <div class="headerIcon d-flex justify-end">
          <v-icon class="secondary--text" @click="closeModalCargarAdjunto">
            mdi-close
          </v-icon>
        </div>
        <v-card-text>
          <section>
            <v-row>
              <v-col>
                <v-tabs-items v-model="tab">
                  <v-tab-item>
                    <br />
                    <v-row>
                      <v-col cols="12" ms="4">
                        <v-text-field
                          v-model="form.nombre_doc"
                          label="Nombre de adjunto *"
                          outlined
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" ms="4">
                        <v-file-input
                          v-model="form.doc"
                          label="Subir adjunto *"
                          multiple
                          outlined
                          accept="application/pdf"
                          show-size
                          counter
                          clearable
                        ></v-file-input>
                      </v-col>
                      <v-col cols="12" ms="4">
                        <v-btn :disabled="isButtonDisabled" @click="agregarDocumento">Agregar documento</v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <data-table-component
                          :inhabilitar_paginacion="true"
                          :tiene_paginacion="false"
                          :headers="headers_documentos"
                          :items="documentos"
                        >
                          <template v-slot:item.documento="{ item }">
                            {{ item.documento?.name }}
                          </template>
                          <template v-slot:item.nombre="{ item }">
                            {{ item.nombre }}
                          </template>
                          <template v-slot:item.acciones="{ item }">
                            <v-icon
                              color="error"
                              class="mr-2"
                              v-for="(accion, index) in item.acciones"
                              :key="index"
                              @click="accion.callback(item)"
                            >
                              mdi-delete
                            </v-icon>
                          </template>
                        </data-table-component>
                      </v-col>
                    </v-row>
                    <br />
                    <br />
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </section>
          <v-row>
            <v-col>
              <v-btn
                class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
                min-width="30%"
                color="secondary"
                outlined
                @click="closeModalCargarAdjunto"
              >
                Cancelar
              </v-btn>
              <v-btn
                class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
                min-width="30%"
                color="secondary"
                @click="createAdjuntoRegCompra"
                :disabled="buttonCargarArchivoDisabled"
              >
                Registrar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>



    <section class="modales">
      <ConfirmationDialogComponent
        :show="confirmSendNotification"
        btnConfirmar="Enviar notificación"
        btnCancel="Abortar"
        :title="`¿Desea notificar a UCP que ha finalizado los registros del fondo de compra por emergencia?`"
        @close="confirmSendNotification = false"
        @confirm="sendNotification"
      />

      <ConfirmationDialogComponent
        :show="confirmDeleteGastoFondo"
        btnConfirmar="Eliminar registro"
        btnCancel="Abortar"
        :title="`¿Desea eliminar el registro seleccionado?`"
        :message="`Esta acción es irreversible`"
        @close="
          (confirmDeleteGastoFondo = false), (registro_selected_var = null)
        "
        @confirm="deleteGastoFondo"
      />

      <!-- Modal para editar -->
      <EditarRegistroCompraPorEmergencia
        :is-open="modalEdicionRegistroAbierta"
        :registro="registro_selected_var"
        :unidades="ctl_unidades"
        @on-visibility-change="manejarVisibilidadModalEdicionRegistro"
        @on-save="confirmarEdicionRegistro"
      />
      <ConfirmationModalComponent
        :is-open="modalConfirmacionEdicionAbierta"
        description="¿Desea continuar con la edición de este registro?"
        :is-loading="edicionCargando"
        @cancel="modalConfirmacionEdicionAbierta = false"
        @confirm="manejarEdicionRegistro"
      />
      <!-- ver observacion -->
      <VisualizarObservacion
        :is-open="modalVerJustificacionAbierta"
        :motivo="justificacionFondoSeleccionado"
        @on-visibility-change="manejarVisibilidadModalVerJustificacion"
      />

      <PdfModal
			:isOpen="modalVisualizacionAdjuntoAbierta" 
			:source-loadable="visualizacionAdjunto" 
			@on-visibility-change="manejarVisibilidadModalVisualizacionAdjunto"
			filename="visualizacion-adjunto"
		/>
      <PdfModal
			:isOpen="modalVisualizacionAdjuntoAbiertaInforme" 
			:source-loadable="visualizacionAdjuntoInforme" 
			@on-visibility-change="manejarVisibilidadVerAdjuntoInforme"
			filename="visualizacion-adjunto"
		/>
    </section>
  </section>
</template>

<script>
// importacion de librerias
import { helpers, required } from "vuelidate/lib/validators";
import EditarRegistroCompraPorEmergencia from "./components/EditarRegistroCompraPorEmergencia.vue";
import VisualizarObservacion from "./components/VisualizarObservacion.vue";
import moment from "moment";
import { mapState } from "vuex";
import excel4node from "excel4node";
import PdfModal from '@/components/PdfModal.vue';

// importacion de componentes globales
import DataTableComponent from "../../components/DataTableComponent.vue";
import DataTableButton from "../../components/DataTableButton.vue";
import MoneyTextFieldComponent from "../../components/MoneyTextFieldComponent.vue";
import ConfirmationDialogComponent from "../../components/ConfirmationDialogComponent.vue";
import { ConfirmationModalComponent } from "@/components/utils";
import formCompra from "@/views/CompraPorEmergencia/formCompra.vue";
import formInforme from "@/views/CompraPorEmergencia/formInforme.vue";
import pdfModalViejo from "@/components/PdfModalClienteViejo.vue";
import {
  createLoadable,
  setLoadableResponse,
  toggleLoadable,
} from "@/utils/loadable";

const txtArea = helpers.regex(
  "txtArea",
  /^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ0-9 ()/.,\n:;-]*$/
);

export default {
  components: {
    DataTableComponent,
    DataTableButton,
    MoneyTextFieldComponent,
    ConfirmationDialogComponent,
    EditarRegistroCompraPorEmergencia,
    ConfirmationModalComponent,
    VisualizarObservacion,
    formCompra,
    pdfModalViejo,
    PdfModal,
    formInforme,
  },

  validations: {
    month_report_var: {
      required,
    },
    num_doc_var: {
      required,
    },
    name_prov_var: {
      required,
    },
    observacion_var: {
      required,
      txtArea,
    },
    area_solic_var: {
      required,
    },
    monto_retenido_var: {
      required,
    },
    monto_total_var: {
      required,
    },
  },

  data: () => ({
    dialogoDocumentos: false,
    idCompra: null,
    path: "",
    documentos: [],
    nombreDocumento: "",
    // constantes
    HEADERS_CONST: [
      {
        align: "center",
        text: "Correlativo",
        value: "correlativo",
      },
      {
        align: "center",
        text: "Descripción de uso de producto o servicio",
        value: "descripcion_uso",
      },
      {
        align: "center",
        text: "Tipo de documento contractual",
        value: "tipo_documento_contractual",
      },
      {
        align: "center",
        text: "Número de documento contractual",
        value: "numero_documento_contractual",
      },
      {
        align: "center",
        text: "Nombre del proveedor",
        value: "nombre_proveedor",
      },
      {
        align: "center",
        text: "Nombre de producto y/o servicio",
        value: "nombre_producto_servicio",
      },
      {
        align: "center",
        text: "Cantidad",
        value: "cantidad",
      },
      {
        align: "center",
        text: "Precio unitario",
        value: "precio_unitario",
      },
      {
        align: "center",
        text: "Total de compra",
        value: "total_compra",
      },
      {
        align: "center",
        text: "Acciones",
        value: "acciones",
        sortable: false,
      },
    ],
    headers_informes: [
      {
        align: "center",
        text: "Correlativo",
        value: "correlativo",
      },
      {
        align: "center",
        text: "Nombre de informe",
        value: "nombre_informe",
      },
      {
        align: "center",
        text: "Nombre de documento",
        value: "nombre_documento",
      },
      {
        align: "center",
        text: "Fondo asignado",
        value: "fondo",
      },
      {
        align: "center",
        text: "Acciones",
        value: "acciones",
        sortable: false,
      },
    ],
    headers_documentos: [
      {
        text: "Documento",
        align: "start",
        value: "documento",
      },
      {
        text: "Nombre de documento",
        align: "center",
        value: "nombre",
      },
      {
        text: "Acciones",
        align: "center",
        value: "acciones",
        sortable: false,
      },
    ],

    // variables
    show_date_picker_var: false,
    show_modal_add_var: false,
    show_modal_agregar_adjunto_compra: false,

    month_report_var: null,
    num_doc_var: null,
    name_prov_var: null,
    observacion_var: null,
    area_solic_var: null,
    monto_retenido_var: null,
    monto_total_var: null,

    show_modal_detail_var: false,
    id_regs_selected_var: null,
    detail_regs_var: {},
    cantidad: null,

    ctl_fondos_var: [],
    id_fondo_selected_var: null,
    list_regs_var: [],
    pagination_var: {
      page: 1,
      per_page: 10,
    },
    pagination_var_informe: {
      page: 1,
      per_page: 10,
    },
    total_rows_var: 0,
    loadingReporteGeneral: false,
    loadingReporteFondo: false,
    loadingSendUCP: false,
    confirmSendNotification: false,
    fondo_var: null,
    ctl_unidades: [],
    confirmDeleteGastoFondo: false,
    registro_selected_var: null,
    diferentUser: null,

    //agregar informe
    show_modal_add_informe: false,
    show_modal_add_registros_informe: false,
    list_informes_var: [],
    cargandoTablaInforme: false,
    paginacion_tabla_informe: {
      page: 1,
      per_page: 10,
    },

    // variables para el modal de carga de excel
    show_upload_excel_file: false,
    file_excel_var: null,
    minDateToReport: null,

    // variables para editar un registro
    modalEdicionRegistroAbierta: false,
    modalConfirmacionEdicionAbierta: false,
    edicionData: null,
    edicionCargando: false,
    modalVerJustificacionAbierta: false,

    cargandoRegistros: false,
    // catalogo de estados
    catalogoEstados: createLoadable([]),
    observacion: "",
    estado: null,
    //visualizar Adjunto
		visualizacionAdjunto: createLoadable(null),
		modalVerAdjunto: false,
		modalVisualizacionAdjuntoAbierta: false,
    //cargar adjunto desde excel
    form: {
      doc: null,
      nombre_doc: null,
    },
     // visualizar adjunto para los informes 
		visualizacionAdjuntoInforme: createLoadable(null),
		modalVerAdjuntoInforme: false,
		modalVisualizacionAdjuntoAbiertaInforme: false,
    id_reg_informe: null,
    data_informes_registros: [],
    tabs: [
      {
        nombre: "Subir adjunto",
      },
    ],
    documentos: [],
    tab: 0,
    infoRegistroCompra: null,
  }),

  computed: {
    ...mapState(["selectedUnidad", "userInfo"]),

    monthReportError() {
      const errors = [];
      if (!this.$v.month_report_var.$dirty) return errors;
      !this.$v.month_report_var.required &&
        errors.push("El mes a reportar es requerido");
      return errors;
    },

    numDocError() {
      const errors = [];
      if (!this.$v.num_doc_var.$dirty) return errors;
      !this.$v.num_doc_var.required &&
        errors.push("El número de documento es requerido");
      return errors;
    },

    nameProvError() {
      const errors = [];
      if (!this.$v.name_prov_var.$dirty) return errors;
      !this.$v.name_prov_var.required &&
        errors.push("El nombre del proveedor es requerido");
      return errors;
    },

    observacionError() {
      const errors = [];
      if (!this.$v.observacion_var.$dirty) return errors;
      !this.$v.observacion_var.required &&
        errors.push("La descripción es requerida");
      !this.$v.observacion_var.txtArea &&
        errors.push("El motivo posee caracteres especiales no permitidos");
      return errors;
    },

    areaSolicError() {
      const errors = [];
      if (!this.$v.area_solic_var.$dirty) return errors;
      !this.$v.area_solic_var.required &&
        errors.push("El área solicitante es requerida");
      return errors;
    },

    montoRetenidoError() {
      const errors = [];
      if (!this.$v.monto_retenido_var.$dirty) return errors;
      !this.$v.monto_retenido_var.required &&
        errors.push("El monto retenido es requerido");
      return errors;
    },

    montoTotalError() {
      const errors = [];
      if (!this.$v.monto_total_var.$dirty) return errors;
      !this.$v.monto_total_var.required &&
        errors.push("El monto total es requerido");
      return errors;
    },

    minimumMonthToReport() {
      return this.minDateToReport;
    },

    justificacionFondoSeleccionado() {
      return this.registro_selected_var
        ? this.registro_selected_var.justificacion
        : "";
    },
    isButtonDisabled() {
      return this.documentos.length === 1;
    },
    buttonCargarArchivoDisabled() {
      return this.documentos.length === 0;
    },
  },

  methods: {
    async cerrarYActualizar() {
      await this.CloseModalAddFtn();
      await this.listarRegistrosInformes();
      await this.FetchListaInformesById();
    },
    CloseModalAddFtn() {
      this.show_modal_add_var = false;
      this.month_report_var = null;
      this.num_doc_var = null;
      this.name_prov_var = null;
      this.observacion_var = null;
      this.area_solic_var = null;

      this.$v.$reset();
    },
    CloseModalDetailFtn() {
      this.show_modal_detail_var = false;
      this.id_regs_selected_var = null;
      this.detail_regs_var = {};
    },

    /**
     *
     * @description: funcion para abrir el modal de detalles del fondo
     * @param {Object} item_param
     */
    OpenModalDetailFtn(item_param) {
      this.id_regs_selected_var = item_param.id;
      this.detail_regs_var = item_param;
      this.show_modal_detail_var = true;
    },
    async PaginateFtn(filters_param) {
      const { cantidad_por_pagina, pagina } = filters_param;

      this.pagination_var.per_page = cantidad_por_pagina;
      this.pagination_var.page = pagina;

      this.FetchListaInformesById();
    },
    async paginateFtnRegistrosInforme(filters_param) {
      const { cantidad_por_pagina, pagina } = filters_param;

      this.pagination_var_informe.per_page = cantidad_por_pagina;
      this.pagination_var_informe.page = pagina;

      this.listarRegistrosInformes();
    },

    /**
     *
     * @description: funcion para obtener la lista de registros por fondo ( API )
     * @param {Boolean} init_param
     */
    async FetchListRegsByFondoFtn(init_param = false) {
      try {
        this.cargandoRegistros = true;
        if (this.id_fondo_selected_var) {
          if (init_param) {
            this.pagination_var.page = 1;
            this.pagination_var.per_page = 10;
          }

          const filters_sc_var = {
            page: this.pagination_var.page,
            per_page: this.pagination_var.per_page,
          };

          const RESP_SC_CONST =
            await this.services.CompraPorEmergencia.getListRegistrosInforme(
              this.id_fondo_selected_var,
              filters_sc_var
            );

          if (RESP_SC_CONST.status === 200) {
            const { data, headers } = RESP_SC_CONST;
            this.list_regs_var = data.registros;
            this.fondo_var = data.fondo;
            this.diferentUser = data.diferentUser;

            this.pagination_var.page = Number(headers.page);
            this.pagination_var.per_page = Number(headers.per_page);
            this.total_rows_var = Number(headers.total_rows);
          }

          this.list_regs_var.forEach((item, index) => {
            item.correlativo = index + 1;
          });
        } else {
          this.list_regs_var = [];
          this.pagination_var.page = 1;
          this.pagination_var.per_page = 10;
          this.total_rows_var = 0;
        }
      } catch (error) {
        this.id_fondo_selected_var = null;
      } finally {
        this.cargandoRegistros = false;
      }
    },

    /**
     *
     * @description: funcion para obtener la lista de fondos catalogo ( API )
     */
    async FetchCtlFondosFtn() {
      const RESP_SC_CONST = await this.services.CompraPorEmergencia.getListFondos({
        pagination: false,
      });

      if (RESP_SC_CONST.status === 200) {
        this.ctl_fondos_var = RESP_SC_CONST.data;
      }
    },

    async downloadReporteGeneral() {
      try {
        this.loadingReporteGeneral = true;
        const { data, status } =
          await this.services.CompraPorEmergencia.getReporteGeneralFondos();
        if (status == 200) {
          const blob = new Blob([data], {
            type: "application/octet-stream",
          });

          const file = new File(
            [blob],
            `reporte-general-fondos${this.moment().format("DD-MM-YYYY")}.xlsx`,
            {
              type: "application/octet-stream",
            }
          );

          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(file);
          link.download = file.name;
          link.click();
        }
      } catch (error) {
        
      } finally {
        this.loadingReporteGeneral = false;
      }
    },
    async downloadReporteFondo() {
      try {
        this.loadingReporteFondo = true;
        const { data, status } =
          await this.services.CompraPorEmergencia.getReporteFondo(
            this.id_fondo_selected_var
          );
        if (status == 200) {
          const blob = new Blob([data], {
            type: "application/octet-stream",
          });

          const file = new File(
            [blob],
            `reporte-fondo${this.moment().format("DD-MM-YYYY")}.xlsx`,
            {
              type: "application/octet-stream",
            }
          );

          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(file);
          link.download = file.name;
          link.click();
        }
      } catch (error) {
        
      } finally {
        this.loadingReporteFondo = false;
      }
    },
    async sendNotification() {
      const { status } =
        await this.services.CompraPorEmergencia.enviarNotificacionUCP();

      if (status == 200) {
        this.temporalAlert({
          message: "Notificación enviada a UCP con éxito",
          show: true,
          type: "success",
        });
      }
      this.confirmSendNotification = false;
    },
    async deleteGastoFondo() {
      const { status } = await this.services.CompraPorEmergencia.deleteGastoFondo(
        this.registro_selected_var.id
      );
      if (status == 200) {
        this.temporalAlert({
          show: true,
          message: "Registro eliminado con éxito",
          type: "success",
        });
        this.FetchListRegsByFondoFtn(true);
      }
      this.confirmDeleteGastoFondo = false;
    },
    showDeleteEvent(data) {
      this.registro_selected_var = data;
      this.confirmDeleteGastoFondo = true;
    },
    // Funciones para carga de archivo excel
    async uploadExcelFile() {
      const formData = new FormData();
      formData.append("file", this.file_excel_var);
      const { status } = await this.services.CompraPorEmergencia.uploadExcel(
        this.id_reg_informe,
        formData
      );
      if (status == 200) {
        this.temporalAlert({
          show: true,
          message: "Datos registrados con éxito",
          type: "success",
        });
      }

      this.CloseModalUploadExcelFile();
    },
    CloseModalUploadExcelFile() {
      this.file_excel_var = null;
      this.show_upload_excel_file = false;
      this.FetchListaInformesById();
      this.listarRegistrosInformes();
    },

    async DescargarArchivo() {
      const workbook = new excel4node.Workbook();
      const sheet = workbook.addWorksheet("Formato");
      const columns = [
        { columna: 1, nombre: "fecha_dl_de" },
        { columna: 2, nombre: "numero_dl_de" },
        { columna: 3, nombre: "fecha_resolucion" },
        { columna: 4, nombre: "referencia_resolucion" },
        { columna: 5, nombre: "descripcion_uso" },
        { columna: 6, nombre: "tipo_documento_contractual" },
        { columna: 7, nombre: "numero_documento_contractual" },
        { columna: 8, nombre: "tipo_documento_fiscal" },
        { columna: 9, nombre: "fecha_emision_fiscal" },
        { columna: 10, nombre: "numero_documento_fiscal" },
        { columna: 11, nombre: "numero_documento" },
        { columna: 12, nombre: "nombre_proveedor" },
        { columna: 13, nombre: "nombre_producto_servicio" },
        { columna: 14, nombre: "cantidad" },
        { columna: 15, nombre: "precio_unitario" },
        { columna: 16, nombre: "total_compra" },
        { columna: 17, nombre: "monto_retenido" },
        { columna: 18, nombre: "observaciones" },
      ];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < columns.length; i++) {
        sheet.cell(1, columns[i].columna).string(columns[i].nombre);
      }

      const data = await workbook.writeToBuffer();
      const url = URL.createObjectURL(
        new Blob([data], { type: "application/vnd.ms-excel" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "formato-compra-por-emergencia.xlsx");
      document.body.appendChild(link);
      link.click();
    },

    async getMinDateToReport() {
      // Excepción para poder ingresar en agosto los gastos de mayo 2023, aunque no cumpla con la validación, después deberá funcionar de la manera establecida
      if (moment().format("MM") == "08" && moment().format("YYYY") == "2023") {
        this.minDateToReport = moment()
          .subtract(3, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        return;
      }

      const fechaActual = moment().startOf("month");

      const response = await this.services.CompraPorEmergencia.postAsueto({
        fecha_inicio: fechaActual,
        dias: 4,
      });
      if (response.status == 200 || response.status == 201) {
        const fechaFinal = moment(response.data.fecha_final).format(
          "YYYY-MM-DD"
        );
        const fechaFinalMoment = moment(fechaFinal);

        if (moment().isBefore(fechaFinalMoment)) {
          this.minDateToReport = moment()
            .subtract(3, "months")
            .startOf("month")
            .format("YYYY-MM-DD");
        } else {
          this.minDateToReport = moment()
            .subtract(2, "months")
            .startOf("month")
            .format("YYYY-MM-DD");
        }
      } else {
        this.temporalAlert({
          show: true,
          message: "No se ha podido obtener la fecha límite para reportar",
          type: "error",
        });
      }
    },

    // Edicion registros
    showEditEvent(data) {
      this.registro_selected_var = { ...data };
      this.modalEdicionRegistroAbierta = true;
    },
    manejarVisibilidadModalEdicionRegistro(visible) {
      this.modalEdicionRegistroAbierta = visible;
    },
    confirmarEdicionRegistro(data) {
      this.edicionData = data;
      this.modalEdicionRegistroAbierta = false;
      this.modalConfirmacionEdicionAbierta = true;
    },
    async manejarEdicionRegistro() {
      try {
        this.edicionCargando = true;
        const idRegistro = this.registro_selected_var.id;
        const { status } = await this.services.CompraPorEmergencia.editarGastoFondo(
          idRegistro,
          this.edicionData
        );

        if (status == 200) {
          this.pushAppMessage({
            type: "success",
            message: "Registro actualizado correctamente.",
          });
          this.FetchListRegsByFondoFtn(true);
        }
      } catch (error) {
        // this.pushAppMessage({ type: 'error', message: 'Error al actualizar el registro.' });
      } finally {
        this.modalConfirmacionEdicionAbierta = false;
        this.edicionCargando = false;
      }
    },
    manejarVisibilidadModalVerJustificacion(visible) {
      this.modalVerJustificacionAbierta = visible;
    },
    abrirModalVerJustificacion(data) {
      this.registro_selected_var = { ...data };
      this.modalVerJustificacionAbierta = true;
    },
    // Cargar catalogo de estados
    // async cargarCatalogoEstados() {
    //   toggleLoadable(this.catalogoEstados);
    //   const { data } =
    //     await this.services.CompraPorEmergencia.cargarCatalogoEstados();
    //   setLoadableResponse(this.catalogoEstados, data);
    // },
    buscarFondos() {
      this.FetchListRegsByFondoFtn(true);
    },
    manejarVisibilidadModalVisualizacionAdjunto(visible) {
			this.modalVisualizacionAdjuntoAbierta = visible;
		},
		async openAdjunto(item) {
			this.modalVisualizacionAdjuntoAbierta = true;
			const idAdjunto = item.DocumentosRegFondoCompraEmergencia[0]?.id;
			toggleLoadable(this.visualizacionAdjunto);
			const { data } = await this.services.CompraPorEmergencia.visualizarAdjuntoRegCompraEmergencia(idAdjunto);
			setLoadableResponse(this.visualizacionAdjunto, data, { isFile: true });
		},
    
    closeModalCargarAdjunto() {
      this.show_modal_agregar_adjunto_compra = false;
      this.form.doc = null;
      this.form.nombre_doc = null;
    },
    async agregarDocumento() {
      if (this.form.doc && this.form.nombre_doc) {
        this.documentos.push({
          documento: this.form.doc[0],
          acciones: [
            {
              icon: "mdi-delete",
              tooltip: "Eliminar",
              callback: (item) => {
                this.documentos = this.documentos.filter(
                  (doc) => doc.documento !== item.documento
                );
              },
            },
          ],
          nombre: this.form.nombre_doc,
        });
        this.form = {
          doc: null,
          nombre_doc: null,
        };
        return;
      }
      if (!this.form.doc)
        this.pushAppMessage({
          message: "Debe seleccionar un archivo",
          type: "info",
        });
      if (!this.form.nombre_doc)
        this.pushAppMessage({
          message: "Debe colocar un nombre al archivo",
          type: "info",
        });
    },
    async cargarAdjuntoRegistroCompra(item) {
      this.show_modal_agregar_adjunto_compra = true
      this.infoRegistroCompra = item;
    },
    async createAdjuntoRegCompra() {
      const formData = new FormData();
			formData.append('id_reg_compra_emergencia', this.infoRegistroCompra?.id);
			formData.append('adjunto', this.documentos[0]?.documento);
			formData.append('nombre_adjunto', this.documentos[0]?.nombre);

			const { data, status } = await this.services.CompraPorEmergencia.crearAdjuntoExcelRegCompra(formData);

			if (status === 200) {
				this.temporalAlert({
					message: data.message,
					show: true,
					type: "success",
				});
			}
			else {
				this.temporalAlert({
					show: true,
					message: data.error.message,
					type: "error",
				});
			}
      this.botonDesactivado = false;
			this.closeModalCargarAdjunto();
			this.FetchListRegsByFondoFtn();
    },
    //agregar informe
    async cerrarActualizarTablaInforme() {
      await this.CloseModalAddInforme();
      await this.FetchListaInformesById();
    },
    CloseModalAddInforme() {
      this.show_modal_add_informe = false;
      this.$v.$reset();
    },
    openModalAgregarRegistrosInforme(item_param) {
      this.id_reg_informe = item_param.id;
      this.show_modal_add_registros_informe = true;
      this.listarRegistrosInformes();
    },
    async PaginateFtn(filters_param) {
      const { cantidad_por_pagina, pagina } = filters_param;

      this.pagination_var.per_page = cantidad_por_pagina;
      this.pagination_var.page = pagina;

      this.FetchListRegsByFondoFtn();
    },
    async FetchListaInformesById(init_param = false) {
      try {
        this.cargandoTablaInforme = true;
        if (this.id_fondo_selected_var) {
          if (init_param) {
            this.paginacion_tabla_informe.page = 1;
            this.paginacion_tabla_informe.per_page = 10;
          }

          const filters_sc_var = {
            page: this.paginacion_tabla_informe.page,
            per_page: this.paginacion_tabla_informe.per_page,
          };

          const RESP_SC_CONST =
            await this.services.CompraPorEmergencia.getListRegsFondos(
              this.id_fondo_selected_var,
              filters_sc_var
            );

          if (RESP_SC_CONST.status === 200) {
            const { data, headers } = RESP_SC_CONST;
            this.list_informes_var = data.registros;
            this.fondo_var = data.fondo;
            this.diferentUser = data.diferentUser;

            this.paginacion_tabla_informe.page = Number(headers.page);
            this.paginacion_tabla_informe.per_page = Number(headers.per_page);
            this.total_rows_var = Number(headers.total_rows);
          }

          this.list_informes_var.forEach((item, index) => {
            item.correlativo = index + 1;
          });
        } else {
          this.list_informes_var = [];
          this.paginacion_tabla_informe.page = 1;
          this.paginacion_tabla_informe.per_page = 10;
          this.total_rows_var = 0;
        }
      } catch (error) {
        this.id_fondo_selected_var = null;
      } finally {
        this.cargandoTablaInforme = false;
      }
    },
    // registros dentro del informe
    closeModalAddRegistrosInforme() {
      this.show_modal_add_registros_informe = false;
      // this.list_regs_var = [];
    },
    manejarVisibilidadVerAdjuntoInforme(visible) {
			this.modalVisualizacionAdjuntoAbiertaInforme = visible;
		},
		async openAdjuntoInforme(item) {
			this.modalVisualizacionAdjuntoAbiertaInforme = true;
			const idAdjunto = item.id;
			toggleLoadable(this.visualizacionAdjuntoInforme);
			const { data } = await this.services.CompraPorEmergencia.visualizarAdjuntoInformeFondo(idAdjunto);
			setLoadableResponse(this.visualizacionAdjuntoInforme, data, { isFile: true });
		},
    mapTipoDocumentoContractual(tipo) {
      switch (tipo) {
        case 1:
          return "Contrato";
        case 2:
          return "Orden de compra";
        default:
          return "---";
      }
    },
    async listarRegistrosInformes() {
			toggleLoadable(this.data_informes_registros);
      const filters_sc_var = {
            page: this.pagination_var_informe.page,
            per_page: this.pagination_var_informe.per_page,
          };
			const { data, headers } = await this.services.CompraPorEmergencia.getListRegistrosInforme(
        this.id_reg_informe,
        filters_sc_var
      );
      this.list_regs_var = data.registros;
      this.total_rows_var = Number(headers.total_rows);

      this.list_regs_var.forEach((item, index) => {
            item.correlativo = index + 1;
          });
			this.data_informes_registros = data;
			setLoadableResponse(this.data_informes_registros, data);
		},
  },
  watch: {
    id_fondo_selected_var(val) {
      if (!val) {
        this.fondo_var = null;
      }
    },
  },
  created() {
    this.FetchCtlFondosFtn();
    this.getMinDateToReport();
    // this.cargarCatalogoEstados();
  },
};
</script>

<style scoped>
.admin-fondos-compra-por-emergencia-table >>> table thead tr,
.admin-fondos-compra-por-emergencia-table >>> table tbody tr td {
  background-color: #fff !important;
}

.admin-fondos-compra-por-emergencia-table >>> table thead tr th:last-child,
.admin-fondos-compra-por-emergencia-table >>> table tbody tr td:last-child {
  position: sticky;
  right: 0;
}

:deep(.btn-documento) {
  padding: 9px 16px !important;
  max-width: 100%; 
  height: auto !important
}

:deep(.btn-documento .v-btn__content) {
  max-width: 100%;
  word-break: break-word;
  overflow: hidden;
  white-space: normal;
}

</style>
